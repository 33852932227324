<template>
  <input type="checkbox"
    :id="id"
    :class="{
      switch: true,
      disabled: disabled,

      primary: variant == 'primary',
      danger: variant == 'danger',
    }"
    :title="status ? labelTrue : labelFalse"
    v-model="status"
    @change="onChange"
    :disabled="disabled || readonly || !allow"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    id: String,
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    labelTrue: {
      type: String,
      default: 'Sim'
    },
    labelFalse: {
      type: String,
      default: 'Não'
    },
    variant: {
      type: String,
      default: 'primary',
    },
  },
  data() {
    return {
      allow: true,
      status: this.value
    }
  },
  methods: {
    onChange(event) {
      this.$emit('change', event.target.checked);
      this.$emit('input', event.target.checked);
    }
  }
};
</script>

<style lang="scss" scoped>
.switch {
  position: relative;
  width: 40px;
  height: 20px;
  appearance: none;
  background: #c6c6c6;
  outline: none;
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, .2);

  &.disabled {
    opacity: .5;
  }

  &:before {
    content: ' ';
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    top: 0;
    left: 0;
    background: #fff;
    transform: scale(1.2);
    transition: .5s;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
  }
}

.switch:checked {
  background: #03a9f4;

  &.danger {
    background: var(--bs-danger);
  }

  &:before {
    left: 20px;
  }
}
</style>