var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input", {
    directives: [
      {
        name: "model",
        rawName: "v-model",
        value: _vm.status,
        expression: "status"
      }
    ],
    class: {
      switch: true,
      disabled: _vm.disabled,

      primary: _vm.variant == "primary",
      danger: _vm.variant == "danger"
    },
    attrs: {
      type: "checkbox",
      id: _vm.id,
      title: _vm.status ? _vm.labelTrue : _vm.labelFalse,
      disabled: _vm.disabled || _vm.readonly || !_vm.allow
    },
    domProps: {
      checked: Array.isArray(_vm.status)
        ? _vm._i(_vm.status, null) > -1
        : _vm.status
    },
    on: {
      change: [
        function($event) {
          var $$a = _vm.status,
            $$el = $event.target,
            $$c = $$el.checked ? true : false
          if (Array.isArray($$a)) {
            var $$v = null,
              $$i = _vm._i($$a, $$v)
            if ($$el.checked) {
              $$i < 0 && (_vm.status = $$a.concat([$$v]))
            } else {
              $$i > -1 &&
                (_vm.status = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
            }
          } else {
            _vm.status = $$c
          }
        },
        _vm.onChange
      ]
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }