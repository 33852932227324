import { render, staticRenderFns } from "./UiSwitch.vue?vue&type=template&id=7374c4a3&scoped=true&"
import script from "./UiSwitch.vue?vue&type=script&lang=js&"
export * from "./UiSwitch.vue?vue&type=script&lang=js&"
import style0 from "./UiSwitch.vue?vue&type=style&index=0&id=7374c4a3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7374c4a3",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Source\\openproject\\go-care\\Frontend\\app\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7374c4a3')) {
      api.createRecord('7374c4a3', component.options)
    } else {
      api.reload('7374c4a3', component.options)
    }
    module.hot.accept("./UiSwitch.vue?vue&type=template&id=7374c4a3&scoped=true&", function () {
      api.rerender('7374c4a3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Ui/UiSwitch.vue"
export default component.exports